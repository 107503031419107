import React, { useState } from 'react';
import { ArrowRight, Users, Zap, Palette, MessageSquare, Moon, Sun } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import Auth from './Auth';
import styles from '../styles/landingPage.module.css';

interface TestimonialCardProps {
    quote: string;
    author: string;
}

interface FeatureCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
}

const LandingPage: React.FC = () => {
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authMode, setAuthMode] = useState<'login' | 'signup' | 'join'>('login');
    const { theme, toggleTheme } = useTheme();

    return (
        <div className={styles.container}>
            {/* Theme Toggle Button */}
            <button
                className={styles.themeToggle}
                onClick={toggleTheme}
                aria-label="Toggle theme"
            >
                {theme === 'light' ? (
                    <div className={styles.iconWrapper}>
                        <Moon size={25} strokeWidth={2.5} />
                    </div>
                ) : (
                    <div className={styles.iconWrapper}>
                        <Sun size={25} strokeWidth={2.5} />
                    </div>
                )}
            </button>

            {/* Hero Section */}
            <section className={styles.heroSection}>
                <h1>Hun Against Humanity</h1>
                <p>The wildly inappropriate party game, now online!</p>
                <div className={styles.buttonContainer}>
                <button
                    className={styles.primaryButton}
                    onClick={() => {
                        setAuthMode('login');
                        setShowAuthModal(true);
                    }}
                >
                   Sign in
                    <ArrowRight className={styles.buttonIcon} />
                </button>
                <button
                        className={styles.secondaryButton}
                        onClick={() => {
                             setAuthMode('join');
                            setShowAuthModal(true);
                        }}
                    >
                        Join the Fun Now
                    <ArrowRight className={styles.buttonIcon} />
                </button>
            </div>
        </section>

         

            {/* Features Section */}
            <section className={styles.featuresSection}>
                <div className={styles.sectionContainer}>
                    <h2>Game Features</h2>
                    <div className={styles.featuresGrid}>
                        <FeatureCard
                            icon={<Users className={styles.featureIcon} />}
                            title="Multiplayer"
                            description="Play with friends in real-time"
                        />
                        <FeatureCard
                            icon={<Zap className={styles.featureIcon} />}
                            title="Dynamic Gameplay"
                            description="Intuitive card selection and play mechanics"
                        />
                        <FeatureCard
                            icon={<Palette className={styles.featureIcon} />}
                            title="Custom Decks"
                            description="Create and manage your own card decks"
                        />
                        <FeatureCard
                            icon={<MessageSquare className={styles.featureIcon} />}
                            title="In-game Chat"
                            description="Communicate with other players while you play"
                        />
                    </div>
                </div>
            </section>

            {/* How to Play Section */}
            <section className={styles.howToPlaySection}>
                <div className={styles.sectionContainer}>
                    <h2>How to Play</h2>
                    <div className={styles.stepsGrid}>
                        <div className={styles.stepCard}>
                            <h3>1. Join a Game</h3>
                            <p>Create a new game or join an existing one. Invite your friends to join the fun!</p>
                        </div>
                        <div className={styles.stepCard}>
                            <h3>2. Play Your Cards</h3>
                            <p>Each round, select the funniest white card to match the black card in play.</p>
                        </div>
                        <div className={styles.stepCard}>
                            <h3>3. Vote and Win</h3>
                            <p>The Card Czar picks the best combination. Collect awesome points to win the game!</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Custom Decks Section */}
            <section className={styles.customDecksSection}>
                <div className={styles.sectionContainer}>
                    <h2>Create Your Own Decks</h2>
                    <p>Unleash your creativity! Design custom decks with your own hilarious cards.</p>
                    <button 
                        className={styles.primaryButton}
                        title="Coming Soon!"
                        aria-label="Start Creating - Coming Soon"
                    >
                        Start Creating
                    </button>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className={styles.testimonialsSection}>
                <div className={styles.sectionContainer}>
                    <h2>What Players Are Saying</h2>
                    <div className={styles.testimonialsGrid}>
                        <TestimonialCard
                            quote="This game is hilariously addictive! I can't stop playing with my friends."
                            author="Sarah K."
                        />
                        <TestimonialCard
                            quote="The custom deck feature is amazing. We created a deck just for our office party!"
                            author="Mike R."
                        />
                        <TestimonialCard
                            quote="Hun Against Humanity is the perfect way to spice up our virtual game nights."
                            author="Emily T."
                        />
                    </div>
                </div>
            </section>

           

            {/* Footer */}
            <footer className={styles.footer}>
                <div className={styles.sectionContainer}>
                    <p>&copy; 2023 Hun Against Humanity. All rights reserved.</p>
                    <p>Made with ❤️ by Chris</p>
                </div>
            </footer>

            {showAuthModal && (
                <Auth
                    initialMode={authMode}
                    onClose={() => setShowAuthModal(false)}
                />
            )}
        </div>
    );
};

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => (
    <div className={styles.featureCard}>
        {icon}
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const TestimonialCard: React.FC<TestimonialCardProps> = ({ quote, author }) => (
    <div className={styles.testimonialCard}>
        <p>"{quote}"</p>
        <p>- {author}</p>
    </div>
);

export default LandingPage;
