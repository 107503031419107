// CreateGameModal.tsx

import { useEffect, useState } from 'react';
import styles from '../../styles/CreateGameModal.module.css';
import { CardPack } from '../../types/game';

interface CreateGameModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCreateGame: (gameSettings: {
        gameName: string,
        winningScore: number,
        selectedBlackPacks: CardPack[],
        selectedWhitePacks: CardPack[],
        botCount: number
    }) => void;
    availablePacks: CardPack[];
}

function CreateGameModal({ isOpen, onClose, onCreateGame, availablePacks }: CreateGameModalProps) {
    const [gameName, setGameName] = useState('');
    const [winningScore, setWinningScore] = useState(10);
    const [selectedBlackPacks, setSelectedBlackPacks] = useState<CardPack[]>([]);
    const [selectedWhitePacks, setSelectedWhitePacks] = useState<CardPack[]>([]);
    const [botCount, setBotCount] = useState(0);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const handlePackSelection = (pack: CardPack, cardType: 'black' | 'white') => {
        const setSelectedPacks = cardType === 'black' ? setSelectedBlackPacks : setSelectedWhitePacks;
        setSelectedPacks(prev =>
            prev.some(p => p.id === pack.id)
                ? prev.filter(p => p.id !== pack.id)
                : [...prev, pack]
        );
    };

    const renderPackSelection = (cardType: 'black' | 'white') => (
        <div className={styles.packGrid}>
            {availablePacks.map(pack => {
                const cardCount = cardType === 'black' ? pack.blackCardCount : pack.whiteCardCount;
                if (cardCount === 0) {
                    return null;
                }
                const isDisabled = cardCount === 0;
                const isSelected = cardType === 'black'
                    ? selectedBlackPacks.some(p => p.id === pack.id)
                    : selectedWhitePacks.some(p => p.id === pack.id);

                return (
                    <div
                        key={`${pack.id}-${cardType}`}
                        className={`${styles.packCard} ${isSelected ? styles.selectedPack : ''} 
                            ${isDisabled ? styles.disabledPack : ''}`}
                        onClick={() => !isDisabled && handlePackSelection(pack, cardType)}
                    >
                        <div className={styles.packImageContainer}>
                            <img src={pack.imageUrl} alt={pack.name} />
                        </div>
                        <div className={styles.packInfo}>
                            <h4 className={styles.packName}>{pack.name}</h4>
                            <span className={styles.cardCount}>{cardCount} cards</span>
                        </div>
                        {isSelected && (
                            <div className={styles.selectedCheckmark}>✓</div>
                        )}
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modernModal}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Create New Game</h2>
                    <button className={styles.closeButton} onClick={onClose}>✕</button>
                </div>

                <div className={styles.modalContent}>
                    <div className={styles.formSection}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label} htmlFor="gameName">Game Name</label>
                            <input
                                className={styles.modernInput}
                                id="gameName"
                                value={gameName}
                                onChange={(e) => setGameName(e.target.value)}
                                placeholder="Enter a fun game name"
                            />
                        </div>

                        <div className={styles.inputRow}>
                            <div className={styles.inputGroup}>
                                <label className={styles.label} htmlFor="winningScore">
                                    Winning Score
                                </label>
                                <div className={styles.sliderContainer}>
                                    <input
                                        type="range"
                                        className={styles.slider}
                                        value={winningScore}
                                        onChange={(e) => setWinningScore(Number(e.target.value))}
                                        min="1"
                                        max="20"
                                    />
                                    <input
                                        className={`${styles.modernInput} ${styles.numberInput}`}
                                        type="number"
                                        value={winningScore}
                                        onChange={(e) => setWinningScore(Number(e.target.value))}
                                        min="1"
                                        max="20"
                                    />
                                </div>
                            </div>

                            <div className={styles.inputGroup}>
                                <label className={styles.label} htmlFor="botCount">
                                    Number of Bots
                                </label>
                                <div className={styles.sliderContainer}>
                                    <input
                                        type="range"
                                        className={styles.slider}
                                        value={botCount}
                                        onChange={(e) => setBotCount(Number(e.target.value))}
                                        min="0"
                                        max="10"
                                    />
                                    <input
                                        className={`${styles.modernInput} ${styles.numberInput}`}
                                        type="number"
                                        value={botCount}
                                        onChange={(e) => setBotCount(Number(e.target.value))}
                                        min="0"
                                        max="10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.packSection}>
                        <h3 className={styles.sectionTitle}>Black Card Packs</h3>
                        {renderPackSelection('black')}

                        <h3 className={styles.sectionTitle}>White Card Packs</h3>
                        {renderPackSelection('white')}
                    </div>
                </div>

                <div className={styles.modalFooter}>
                    <button
                        className={`${styles.modernButton} ${styles.ghostButton}`}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className={styles.modernButton}
                        onClick={() => onCreateGame({
                            gameName,
                            winningScore,
                            selectedBlackPacks,
                            selectedWhitePacks,
                            botCount
                        })}
                        disabled={selectedBlackPacks.length === 0 || selectedWhitePacks.length === 0 || !gameName}
                    >
                        Create Game
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateGameModal;
