import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Lobby from './components/Lobby';
import Game from './components/Game';
import DeckManagement from './components/DeckManagement';
import LandingPage from './components/LandingPage';
import Nav from './components/Nav';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import socket from './services/socket';
import { ThemeProvider } from './contexts/ThemeContext';
import './styles/themes.css';
import Admin from './components/Admin';

// Main application component that handles routing and authentication
const AppContent: React.FC = () => {
  // State for tracking online users
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const { isAuthenticated, username } = useAuth();

  useEffect(() => {
    if (username) {
      // Connect socket and announce user presence
      socket.connect();
      socket.emit('userOnline', username);

      // Set up heartbeat to maintain connection
      const heartbeatInterval = setInterval(() => {
        if (socket.connected) {
          socket.emit('heartbeat', username);
        }
      }, 10000);

      // Handle socket reconnection
      socket.on('connect', () => {
        socket.emit('userOnline', username);
      });

      // Update online users list when received from server
      socket.on('onlineUsersUpdate', (users: string[]) => {
        setOnlineUsers(users);
      });

      // Cleanup socket listeners on unmount
      return () => {
        clearInterval(heartbeatInterval);
        socket.off('connect');
        socket.off('onlineUsersUpdate');
      };
    }
  }, [username]);

  // Show landing page if user is not logged in
  if (!username) {
    return <LandingPage />;
  }

  // Main application layout with routes
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Nav socket={socket} />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Lobby onlineUsers={onlineUsers} socket={socket} />} />
            <Route path="/:gameId" element={<Game />} />
            {/* Protected route - only accessible when authenticated */}
            <Route
              path="/deck-management"
              element={isAuthenticated ? <DeckManagement /> : <Navigate to="/" replace />}
            />
            <Route
              path="/admin"
              element={
                isAuthenticated && username === 'chris'
                  ? <Admin />
                  : <Navigate to="/" replace />
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

// Wrap the application with AuthProvider for authentication context
const App: React.FC = () => {
  return (
    <AuthProvider>

      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
